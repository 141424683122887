<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                    v-model="datatable.queries.per_page"
                    class="ml-1 mr-1"
                    size="sm"
                    :options="[10, 15, 25, 50, 100]"
                    @change="getArticles"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter text-md-right">
              <router-link
                  :to="{
                        name: 'agency.content.create'
                      }"
                  class="btn btn-success btn-sm mr-4"
              >Create Content</router-link>
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
              <button
                  class="ml-1 btn btn-dark-blue btn-sm"
                  @click="getArticles"
              >
                <i class="uil uil-search-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <template v-if="!loadingAgencies">
              <div class="table-responsive mb-0">
                <b-table
                    :items="articles"
                    :fields="datatable.columns"
                    :sort-by="datatable.queries.sort_by"
                    :sort-desc="datatable.queries.sort_desc"
                    :no-local-sorting="true"
                    @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(has_plan)="pData">
                    <feather v-if="pData.item.has_plan" type="check" class="text-success" />
                    <feather v-else type="x" class="text-danger"/>
                  </template>
                  <template v-slot:cell(api_model)="pData">
                    <div v-if="!pData.item.api_model">
                      gpt-3.5
                    </div>
                    <div v-else>{{pData.item.api_model}}</div>
                  </template>
                  <template v-slot:cell(topic)="data" class="topic">
                    <div v-if="data.item.type === 0">
                      {{data.item.freeform_prompt.substring(0,100)}}<span v-if="data.item.freeform_prompt.length > 100">...</span>
                    </div>
                    <div v-else>
                      {{data.item.topic}}
                    </div>
                  </template>
                  <template v-slot:cell(actions)="data" class="actions">
                    <router-link
                        :to="{
                        name: 'agency.content.view',
                        params: { id: data.item.id },
                      }"
                        class="btn btn-link btn-action mr-1"
                    >
                      <i class="uil uil-edit"></i>
                    </router-link>
                    <b-button
                        variant="link"
                        title="Delete"
                        class="btn-action text-danger"
                        :disabled="loadingRemove === data.item.id"
                        @click="archive(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash-alt"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalArticles }} projects
                  </div>
                  <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          v-model="datatable.queries.page"
                          :total-rows="totalArticles"
                          :per-page="datatable.queries.per_page"
                          @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showDeleteModal" title="Delete agency">
      <p v-if="selectedArticle"
      >Are you sure you want to delete the content "{{ selectedArticle.name }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
        >Cancel</b-button
        >
        <b-button variant="danger" @click="handleArchive">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'small_date', label:'Date', sortable: true },
          { key: 'name', sortable: true },
          { key: 'type_text', label: 'Type', sortable: true },
          { key: 'tone', label: 'Tone', sortable: true },
          { key: 'api_model', label: 'Model', sortable: true },
          { key: 'topic', label: 'Keyword/Topic', sortable: true, width:100 },
          { key: 'actions', label:'', sortable:false, class: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'id',
          sort_desc: false,
        },
      },
      showDeleteModal: false,
      selectedArticle: null,
      loadingAgencies: false,
      loadingRemove: null,
    }
  },

  computed: {
    articles() {
      return this.$store.getters['content/all'] || []
    },

    totalArticles() {
      return this.$store.getters['content/total']
    },
  },

  created() {
    this.$store.dispatch('agency/setCurrentPage', 'index')
  },

  mounted() {
    this.getArticles();
    if (this.$store.getters['content/all'] === null) {
      this.getArticles()
    } else {
      this.datatable.queries = this.$store.getters['content/queries']
    }
  },

  methods: {
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getArticles()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getArticles()
    },

    getArticles() {
      this.loadingAgencies = true

      this.$store
          .dispatch('content/getAll', {
            queries: this.datatable.queries,
            page: 'index',
          })
          .then(() => {
            this.loadingAgencies = false
          })
          .catch(() => {
            this.loadingAgencies = false
          })
    },

    archive(article) {
      this.showDeleteModal = true
      this.selectedArticle = article
    },

    handleArchive() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedArticle.id
      this.$store
          .dispatch('content/archive', this.selectedArticle.id)
          .then(() => {
            this.loadingRemove = null
            this.getArticles()
          })
          .catch(() => {
            this.loadingRemove = null
          })
    },
  },
}
</script>
